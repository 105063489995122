import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { navigate } from 'gatsby';
import { Text, Box, Button, Flex } from 'rebass';
import { Input } from '@rebass/forms';
import Layout from '../components/Layout';
import registerBG from '../images/register-bg.jpg';
import { COLOR } from '../lib/styles';
import * as PropTypes from '../lib/proptypes';
import { $subscribeNewsletter } from '../components/Shared/state';

const container = {
  backgroundImage: `url('${registerBG}')`,
  paddingTop: '100px',
  width: '100%',
  height: '100vh',
  backgroundSize: 'cover',
  textAlign: 'center',
  justifyContent: 'center',
};

const withStore = connect((state) => ({
  post: state.Shared.selectedPost,
}));

const Wrapper = (C) => withStore(C);

function JoinNewsletterPage({ dispatch }) {
  const [formData, setFormData] = useState({
    email: '',
  });
  const [redirectReady, setRedirectReady] = useState(false);
  // Makes sure gatsby build goes through since navigate
  // isn't available in the node runtime
  useEffect(() => {
    setRedirectReady(true);
  }, []);

  function isValidEmail() {
    const regex = new RegExp(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/);
    return regex.test(formData.email) && formData !== 'email@example.com';
  }
  if (!redirectReady) {
    return null;
  }
  return (
    <Layout padder>
      <div style={container}>
        <Text fontSize={[4, 5, 6]} p={3} fontFamily="Lato" color={COLOR.primary}>
          Subscribe to newsletter
        </Text>
        <Flex>
          <Box width={1 / 3} />
          <Box width={[1 / 3]}>
            <Input
              id="email"
              name="email"
              type="email"
              placeholder="email@example.com"
              value={formData.email}
              color="black"
              height="5vh"
              my={3}
              backgroundColor="white"
              onChange={(e) => setFormData({ ...formData, email: e.target.value })}
            />
            <Button
              style={{ cursor: 'pointer' }}
              width="100%"
              my={3}
              backgroundColor={isValidEmail() ? '#227bdf' : '#ffccc7'}
              variant="primary"
              height="5vh"
              type="submit"
              onClick={() => {
                if (isValidEmail()) {
                  dispatch($subscribeNewsletter(formData.email))
                    .catch((e) => console.log('An error occured', e))
                    .finally(() => {
                      setFormData({
                        ...formData,
                        id: '',
                        code: '',
                        email: '',
                      });
                      navigate('/');
                    });
                }
              }}
              disabled={!isValidEmail()}
            >
              {isValidEmail() ? 'Submit email address' : 'Please enter a valid email'}
            </Button>
          </Box>
          <Box width={1 / 3} />
        </Flex>
      </div>
    </Layout>
  );
}

JoinNewsletterPage.propTypes = {
  ...PropTypes.withState,
};

export default Wrapper(JoinNewsletterPage);
